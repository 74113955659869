import { render, staticRenderFns } from "./HuiYuanXXUpdate.vue?vue&type=template&id=56192a62&scoped=true&"
import script from "./HuiYuanXXUpdate.vue?vue&type=script&lang=js&"
export * from "./HuiYuanXXUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56192a62",
  null
  
)

export default component.exports