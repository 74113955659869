<template>
    <div>
        <el-dialog title="编辑信息" :visible.sync="editxxFormVisible">
            <el-form :model="editxxform" :rules="rules">
                <el-form-item label="会员标签" prop="huiYuanBQ">
                    <!--                <el-input v-if="form.id!=null " v-model="form.huiYuanBiaoQian" size="small" maxlength="" disabled-->
                    <!--                          show-word-limit/>-->
                    <!--                <el-input v-else v-model="form.huiYuanBiaoQian" size="small" maxlength="" show-word-limit/>-->
                    <x-hy-tag-selector v-model="editxxform.huiYuanBQ" edit></x-hy-tag-selector>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth" prop="shouJiHao">
                    <el-input v-model="editxxform.shouJiHao" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="等级" :label-width="formLabelWidth" prop="dengJi" v-if="editxxform.dengJi!=null">
                    <!--                    <el-select v-model="editxxform.dengJi" placeholder="请选择">-->
                    <!--                        <el-option-->
                    <!--                                v-for="item in hydjList"-->
                    <!--                                :key="item.huiYuanDJ"-->
                    <!--                                :label="item.huiYuanMC"-->
                    <!--                                :value="item.huiYuanDJ">-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                    <hui-yuan-d-j-selector v-model="editxxform.dengJi"/>

                </el-form-item>
                <el-form-item label="剩余金额" :label-width="formLabelWidth" prop="shengYuJinE">
                    <el-input v-model="editxxform.shengYuJinE" autocomplete="off" disabled>
                      <span slot="suffix">
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
                <el-form-item label="赠送金额" :label-width="formLabelWidth" prop="jinE">
                    <!--                    <el-input v-model="editxxform.jinE" autocomplete="off"></el-input>-->
                    <el-input-number v-model="editxxform.jinE" :precision="2" :step="0.1" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="积分" :label-width="formLabelWidth" prop="jiFen">
                    <!--                    <el-input v-model="editxxform.jiFen" autocomplete="off"></el-input>-->
                    <el-input-number v-model="editxxform.jiFen" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editxxFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editxx">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import {validMobilePhone, ruleBuilder} from "@/util/validate";
    import HuiYuanDJSelector from "@/view/selector/HuiYuanDJSelector";
    import Edit from "@/view/hygl/hyxx/HuiYuanXXEdit";
    import XHyTagSelector from "@/view/hygl/hybq/XHyTagSelector";

    export default {
        name: "HuiYuanXXUpdate",
        mixins: [],
        components: {HuiYuanDJSelector, XHyTagSelector},
        data() {
            let {required, mobilePhone}=ruleBuilder;
            return {
                //修改信息表单
                editxxFormVisible: false,
                editxxform: {
                    id: '',
                    shouJiHao: '',
                    jinE: null,
                    dengJi: null,
                    jiFen: null,
                    chongZhiJinE: null,
                    shengYuJinE: null,
                    huiYuanBQ: [],
                },
                formLabelWidth: '120px',
                rules:{
                    shouJiHao: [required(), mobilePhone()],
                    jinE: [required()],
                    dengJi: [required()],
                    jiFen: [required()],
                    huiYuanBQ: [],
                }
            };
        },
        methods: {
            editxxshow(id) {
                this.editxxFormVisible = true;
                this.editxxform.id = id
                hyxxservice.getUpdate(id).then(res => {
                    if (res.code === 200) {
                        console.log(res.data)
                        this.editxxform.shouJiHao = res.data.shouJiHao;
                        this.editxxform.dengJi = res.data.huiYuanDJ;
                        this.editxxform.jinE = res.data.zengSongJinE;
                        this.editxxform.jiFen = res.data.shengYuJiFen;
                        this.editxxform.shengYuJinE = res.data.shengYuJinE;
                        this.editxxform.chongZhiJinE = res.data.chongZhiJinE;
                        if (res.data.huiYuanBQ) {
                            this.editxxform.huiYuanBQ = res.data.huiYuanBQ;
                        } else {
                            this.editxxform.huiYuanBQ = [];
                        }
                        this.editxxform.id = id;
                    }
                })
            },
            editxx() {
                var b = validMobilePhone(this.editxxform.shouJiHao);
                if (b) {
                    this.editxxFormVisible = false;
                    var data = {
                        shouJiHao: this.editxxform.shouJiHao,
                        dengJi: this.editxxform.dengJi,
                        jinE: this.editxxform.jinE,
                        jiFen: this.editxxform.jiFen,
                        id: this.editxxform.id,
                        caoZuoRenYuan: this.userName,
                        bianDongMenDian: this.menDianMC,
                        huiYuanBQ: this.editxxform.huiYuanBQ,
                    };
                    hyxxservice.updateXX(data).then(res => {
                        if (res.code === 200) {
                            console.log(res)
                            this.$message.success("修改成功")
                            this.$emit('submitted')
                        } else {
                            this.$message.error("修改失败")
                        }
                    })

                } else {
                    this.$message.warning("手机号格式错误")
                }

            },
        }
    };
</script>

<style lang="scss" scoped>

</style>