<template>
    <div>
        <el-dialog title="设置plus折扣" :visible.sync="plusZKFormVisible">
            <el-form :model="plusZKform" :rules="rules">
                <el-form-item label="折扣" :label-width="formLabelWidth" prop="zk">
                    <el-input v-model="plusZKform.zk" autocomplete="off" placeholder=" 例:9折，请输入:0.9"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="plusZKFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="pulsZK">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";

    export default {
        name: "plusZK",
        mixins: [],
        data() {
            // this.searchNoClear = {current: 1, size: 10};
            // this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            // this.service = service;
            return {
                plusZKFormVisible: false,
                plusZKform: {
                    zk: "",
                },
                formLabelWidth: '120px',
                rules:{

                }
            };
        },
        methods: {
            pulsZKshow() {
                this.plusZKFormVisible = true;
            },
            pulsZK() {
                var b = /^(0\.\d{1,2}|0?[.][1-9]\d?|0?[.][0-9][1-9])$/.test(this.plusZKform.zk);
                if (b) {
                    const zhekou = this.plusZKform.zk;
                    if (zhekou > 0 && zhekou < 1) {
                        hyxxservice.setPlusZK(zhekou).then(res => {
                            if (res.code === 200) {
                                this.$message.success("plus折扣设置成功")
                                this.$emit('success');
                            } else {
                                this.$message.warning("设置失败")
                            }
                        })
                    } else {
                        this.$message.warning("格式有误")
                    }
                    this.plusZKFormVisible = false;
                } else {
                    this.$message.warning("格式有误");
                }
            },
        }
    };
</script>

<style lang="scss" scoped>

</style>