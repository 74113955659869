<template>
    <div >
            <el-dialog title="会员信息导入" :visible.sync="xxdrFormVisible" >
                <div class="x-search-item">
                    <el-select  v-model="drMenDian"  placeholder="请选择门店" >
                        <el-option
                                v-for="item in menDianList"
                                :key="item.menDianMC"
                                :label="item.menDianMC"
                                :value="item.menDianMC">
                        </el-option>
                    </el-select>
                    <label style="margin-left: 30px;font-weight: bold">导入会员信息：</label>
                    <div  style="line-height: 35px">
                        <input id="drFile" type="file" ref="upload" @change="drFile" accept=".xls,.xlsx" >
                    </div>
                    <el-button type="primary" size="mini">
                        <a  href="./static/hygl/会员信息.xlsx" style="color: #fff" download="会员信息.xlsx">下载导入模板</a>
                    </el-button>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="xxdrFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>
    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import {DICT_TYPE_HUI_YUAN_LX, DICT_TYPE_KA_ZT, DICT_YES_OR_NO} from "@/util/constant";
    export default {
        name:"XinXiDaoRu",
        mixins: [],
        data() {
            return {
                xxdrFormVisible:false,
                menDianList:[],
                isShanghu:false,
                userName:'',
                menDianMC:'',
                drMenDian:'',
            };
        },
        mounted() {

            // const inputFile=document.getElementById('drFile')
            // inputFile.addEventListener('change', e => {//绑定监听表格导入事件
            //     this.readExcel(e);
            //     console.log("00000000")
            // })

            // 获取当前门店和操作人
            menDianXXservice.getCurrentMenDianXXFromRedis().then(res=>{
                console.log(this.$store.getters.user);
                if(!res.data){
                    menDianXXservice.checkboxList().then(res=>{
                        console.log(res);
                        if (res.code===200){
                            this.menDianList=res.data
                        }
                    });
                    this.userName=this.$store.getters.user.name;
                    this.menDianMC="商户"
                    this.isShanghu=true;
                    console.log(this.drMenDian);
                    console.log(this.userName);
                    console.log(this.menDianMC)
                }else {
                    this.userName=this.$store.getters.user.name;
                    this.menDianMC=res.data.menDianMC;
                    this.drMenDian=res.data.menDianMC;
                    console.log(this.drMenDian);
                    console.log(this.userName);
                    console.log(this.menDianMC)
                }
            })
        },
        watch:{

        },
        methods:{
            drFile(e){
                console.log("监听导入事件，是否为商户：",this.isShanghu)
                console.log("监听导入事件，导入门店信息：",this.drMenDian)
                if (this.isShanghu && this.drMenDian===''){
                    this.$message.warning("请先选择要导入的门店")
                    return
                }else {
                    this.readExcel(e)
                }

            },
            dalogShow(){
                this.xxdrFormVisible=true;
            },
            readExcel(e) {
                //表格导入// 引入工具库 import 也可以，前提是已经npm 下来了。
                var XLSX = require("xlsx");
                const files = e.target.files;
                var vali=/\.(xls|xlsx)$/;
                if(files.length<=0){
                    //如果没有文件名
                    return false;
                }else if(!vali.test(files[0].name.toLowerCase())){
                    this.$Message.error('上传格式不正确，请上传xls或者xlsx格式');
                    return false;
                }
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        // data则是我们在excel表中获取的数据
                        const Workbook = XLSX.read(data, { type: 'binary'});// 通过XLSX读取出来得到了一个 workdata 对象，下面详细介绍 workdata 对象中有些什么
                        console.log(Workbook);
                        let workSheet = Workbook.Sheets["Sheet1"];
                        console.log(workSheet)
                        var hyList = XLSX.utils.sheet_to_json(workSheet);
                        console.log(hyList)
                        var count=0;
                        var flag1=true;
                        for (var i=0;i<hyList.length;i++){
                            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(hyList[i].手机号) && !!hyList[i].手机号){
                                this.$message.warning("手机号: "+hyList[i].手机号+" 格式有误！")
                                flag1=false;
                            }
                            if (!/^\d{6,15}$/.test(hyList[i].卡号) && !!hyList[i].卡号){
                                console.log(hyList[i].卡号)
                                this.$message.warning("卡号: "+hyList[i].卡号+" 格式有误！请确保卡号长度在6-15位之间。")
                                flag1=false;
                            }
                            for (var j=i+1;j<hyList.length;j++){
                                console.log(i+"============"+j)
                                if (hyList[i].手机号===hyList[j].手机号  && !!hyList[i].手机号){
                                    this.$message.warning("手机号: "+hyList[j].手机号+" 存在重复数据")
                                    flag1=false;
                                }
                                if (hyList[i].卡号===hyList[j].卡号  && !!hyList[i].卡号){
                                    this.$message.warning("卡号: "+hyList[j].卡号+" 存在重复数据")
                                    flag1=false;
                                }
                            }
                        }
                        if (flag1){
                            console.log(hyList.length)
                            for (var i=0;i<hyList.length;i++){
                                if (!!hyList[i].卡号 && !!hyList[i].手机号){
                                    console.log(hyList[i])
                                    var hy=hyList[i];
                                    let data={
                                        kaHao: hy.卡号, // 卡号
                                        xingMing: hy.姓名, // 姓名
                                        shouJiHao: hy.手机号, // 手机号
                                        miMa: "000000", // 密码
                                        huiYuanLeiXing: hy.会员类型, // 会员类型
                                        huiYuanBiaoQian: "", // 会员标签
                                        plusZheKou: "", // plus折扣
                                        leiJiJiFen: hy.累计积分, // 累计积分
                                        shengYuJiFen: hy.剩余积分, // 剩余积分
                                        shengYuJinE: hy.剩余金额, // 剩余金额
                                        chongZhiJinE: hy.充值金额, // 充值金额
                                        zengSongJinE: hy.赠送金额, // 赠送金额
                                        huiYuanDJ: "", // 会员等级
                                        kaZhuangTai: DICT_TYPE_KA_ZT.ZC.key, // 卡状态
                                        kaiKaSJ: new Date(), // 开卡时间
                                        shiFuZhuXiao: DICT_YES_OR_NO.NO.key, // 是否注销
                                        shiFuShanChu: DICT_YES_OR_NO.NO.key, // 是否删除
                                        suoShuMenDian: this.drMenDian, // 所属门店
                                        caoZuoRen: this.userName, // 操作人
                                    }
                                    hyxxservice.add(data).then(res=>{
                                        console.log(data)
                                        console.log(res)
                                        count=count+1;
                                        console.log(count)
                                        this.$message.info("已导入"+count+"条数据")
                                    })
                                }

                            }
                        }
                        this.$refs.upload.value = '';// 处理完成 清空表单值
                    } catch (e) {
                        return false;
                    }
                };
                fileReader.readAsBinaryString(files[0]);
            }

        }
    };
</script>

<style lang="scss" scoped>
    .el-dialog-div{
        height: 60vh;//如果高度过高，可用max-height
        overflow: auto;
    }
</style>