<template>
    <div>
        <div>
            <el-dialog title="修改密码" :visible.sync="editPwdFormVisible">
                <el-form :model="editPwdform">
                    <el-form-item label="密码" :label-width="formLabelWidth" prop="pwd">
                        <el-input v-model="editPwdform.pwd" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editPwdFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editPwd">确 定</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";

    export default {
        name: "pwdUpdate",
        mixins: [],
        data() {
            // this.searchNoClear = {current: 1, size: 10};
            // this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            // this.service = service;
            return {
                editPwdFormVisible: false,
                editPwdform: {
                    pwd: "",
                    id: '',
                },
                formLabelWidth: '120px',
            };
        },
        methods: {
            editPwdshow(id) {
                this.editPwdFormVisible = true;
                this.editPwdform.id = id
            },
            editPwd() {
                if (this.editPwdform.pwd.length > 20 || this.editPwdform.pwd.length < 6) {
                    this.$message.warning("格式不正确，请输入正确的格式")
                } else {
                    let data = {
                        id: this.editPwdform.id,
                        pwd: this.editPwdform.pwd,
                        caoZuoRenYuan: this.$store.getters.user.name,
                    };
                    console.log(this.editPwdFormVisible)
                    this.editPwdFormVisible = false;
                    hyxxservice.editPwd(data).then(res => {
                        console.log(res);
                        if (res.code === 200) {
                            this.$message.success("密码已修改");
                            this.editPwdform.pwd = ""
                        } else {
                            this.$message.warning("修改失败")
                            this.editPwdform.pwd = ""
                        }
                    })
                }

            },


        }
    };
</script>

<style lang="scss" scoped>

</style>