<template>
    <div>
        <div>
            <el-dialog title="可用优惠券" :visible.sync="myYhqVisible" width="80%">
                <!-- 查询区 -->

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
                    <!--                    <el-table-column type="selection" width="40" fixed/>-->
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column width="100" prop="huiYuanMC" label="会员名称"/>
                    <el-table-column width="180" prop="youHuiQuanMC" label="优惠券名称"/>
                    <el-table-column width="100" prop="youHuiMenJian" label="优惠门槛"/>
                    <el-table-column width="100" prop="youHuiJinE" label="优惠金额"/>
                    <el-table-column width="180" prop="youXiaoQiShiSJ" label="有效起始时间"/>
                    <el-table-column width="180" prop="youXiaoJieShuSJ" label="有效结束时间"/>
                    <el-table-column width="100" prop="youHuiQuanZT" label="优惠券状态">
                        <x-dict-show slot-scope="{row}" :code="row.youHuiQuanZT" dictType="T_YHQ_ZT"/>
                    </el-table-column>
                    <el-table-column width="180" prop="huiYuanShouJiHao" label="会员手机号"/>
                    <el-table-column width="180" prop="faFangSJ" label="发放时间"/>
                </el-table>
                <!-- 分页区域 -->

            </el-dialog>

        </div>

    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";

    export default {
        name: "MyYhq",
        mixins: [],
        data() {
            // this.searchNoClear = {current: 1, size: 10};
            // this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            // this.service = service;
            return {
                myYhqVisible: false,
                table: {
                    loading: false,
                    data: [],
                    total: '',
                    height: 500
                },
                search: {}
            };
        },
        methods: {
            myYhqShow(id) {
                this.myYhqVisible = true;
                hyxxservice.getMyYhq(id).then(res => {
                    console.log("这是我的优惠券：", res.data);
                    this.table.data = res.data;
                    this.table.data ? this.table.total = res.data.length : 0

                })

            },


        }
    };
</script>

<style lang="scss" scoped>

</style>